import React from 'react';
import Deer from '../deer/deer';
import styles from './mobileHome.module.scss';
import style from '../../scss/over.module.scss';
import { Link } from 'gatsby';

const MobileHome = () => {
  return (
    <div className={`${styles.mobileHome} ${style.mobileHome} `}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Saranga Publishing</h1>
        <Deer height="60%" width="90vw" />
        <h3 className={styles.description}>
          Igniting Indian Intellectual Vitality
        </h3>
      </div>
      <nav className={styles.nav}>
        <div className={`${styles.menu} ${styles.focus}`}>Home</div>

        <Link to="/book">
          <div className={`${styles.menu}`}>Books</div>
        </Link>
      </nav>
    </div>
  );
};

export default MobileHome;
